<template>
    <div class="form-group">
        <label class="form-label" :for="id">{{ title }}</label>
        <IntlTelInput ref="intl"
            class="form-control mobile"
            v-model="internalMobile"
            :disabled="disabled"
            :name="name"
            :id="id"
            @changeNumber="numberChange($event)"
            @changeCountry="countryChanged($event)"
            @changeValidity="validationChanged($event)"
            :class="{ 'is-invalid' : internalMobile?.length && !isValid }" 
            :autocomplete="name"
            :value="internalMobile"
            :options="options"
            :required="required"
        />
        <span class="invalid-feedback mt-0 d-block" v-if="internalMobile?.length && !isValid" >Invalid Mobile No</span>
    </div>
</template>
<style>
    .iti { width: 100%; }
    .iti__selected-dial-code {
        font-size: initial!important;
        min-width: 35px;
        text-align: end;
        margin-left: 0px;
    }
    input.mobile {
        padding-left: 45px!important; 
    }
    .invalid-feedback{
        display: block!important;
    }
</style>
<script>
    let stylesLoaded = false;
</script>
<script setup>
    import { ref, onMounted } from 'vue'
    import { IntlTelInput  } from 'intl-tel-input';
    if(!stylesLoaded){
        import('o365.modules.helpers.js').then(async helpers => { 
            const customCssFileName = 'intl-tel-input/css/intlTelInput.min.css';
            const libUrl = await helpers.getLibUrl(customCssFileName);
            if (libUrl != customCssFileName) {
                helpers.loadStyle(libUrl).then(() => setTimeout(() => isBusy.value = false, 50 ));
            }
        });
        stylesLoaded = true;
    }
    const defaultCountry = 'no';
    const options = {
        initialCountry: 'auto',
        separateDialCode: false,
        strictMode: true,
        nationalMode: false,
        geoIpLookup: function(callback){
            setTimeout(()=>getDefaultCountryCode(callback), 200);            
        }
    };
    
    const props = defineProps({
        id:String,
        name:String,
        title:String,
        disabled:Boolean,
        required:Boolean
    });
    const isBusy = ref(true);
    const intl = ref({});
    const internalMobile = defineModel();
    const isValid = defineModel('isValid', {
        default: false
    });

    onMounted(() => {

    });

    function getDefaultCountryCode(success) {
        var countryCode = localStorage.getItem("countryCode");
        if(!countryCode && props.initialCountry) {
            countryCode = props.initialCountry;
        }
        if(countryCode) return success(countryCode);
        fetch('https://ipinfo.io', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(function(res) { return res.json(); })
        .then(function(data) { 
            var countryCode = (data && data.country) ? data.country : defaultCountry;
            localStorage.setItem("countryCode", countryCode);
            success(countryCode); 

        })
        .catch(function() { failure(defaultCountry); });
    }

    function numberChange(number){
        if(!intl.value?.instance) return;
        internalMobile.value = intl.value.instance.getNumber(0);
        console.log("mobile: ", internalMobile.value, ", ID: ", props.id);
    }

    function countryChanged(country){
        console.log("country changed:", country, ", ID: ", props.id);
        if(country !== defaultCountry){
            localStorage.setItem("countryCode", country);
        }
    }

    function validationChanged(validity){
        console.log("Is Valid:", validity, ", ID: ", props.id);
        isValid.value = validity;
    }

</script>